import setNotification from "components/shared-components/Notification"
import moment from "moment"
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { createLoginActivity } from "redux/actions/Auth"
import {
  setCollectedReward,
  setCollectedRewardImages,
  setIsCollected,
  setIsLoading,
} from "redux/actions/CollectReward"
import {
  GET_COLLECTED_REWARD,
  ON_COLLECT_REWARD,
  ON_UPDATE_COLLECTED_REWARD,
} from "redux/constants/CollectReward"
import CommonService from "services/CommonService"
import CustomerRewardService from "services/CustomerRewardService"
// import ProntoSyncVoucherService from "services/ProntoSyncVoucherService"

const setError = (message, description) =>
  setNotification({
    type: "error",
    message,
    description,
  })

export function* getCollectedRewardAPI() {
  yield takeEvery(GET_COLLECTED_REWARD, function* ({ payload }) {
    try {
      const userInfo = JSON.parse(localStorage.getItem("userinfo"))

      const { data } = yield call(
        CustomerRewardService.getCustomerReward,
        payload.ReferenceCode
      )

      const sanitizeMemberIdValue = (id) => id.replace(/\s+/g, "").trim()

      const sanitizedMemberIdValue = sanitizeMemberIdValue(
        payload.MemberIdValue
      )

      data.MembershipNo =
        payload.MemberId === "MembershipNo"
          ? sanitizedMemberIdValue
          : data.Customer.CustomerMemberships[0].MembershipNo

      const VoucherType = data.Reward?.Type
      const RewardStatus = data.Reward?.Status

      if (VoucherType !== "Premium Gift" || RewardStatus !== "Active") {
        setError("Get Collect Reward Failed", "Record not found")
        return
      }

      if (data.Status !== "Active" && data.Status !== "Collected") {
        setError("Get Collect Reward Failed", "Record not found")
        return
      }

      const isCollected = data.Status === "Collected"

      if (isCollected) {
        setError("Get Collect Reward Failed", "The item has been collected")
        yield put(setIsCollected(isCollected))
        yield put(setCollectedReward(data))

        return
      }

      const isMembership = data.Customer.CustomerMemberships.some(
        ({ MembershipNo }) => MembershipNo === sanitizedMemberIdValue
      )
      const isIdType = data.Customer.IdNo === sanitizedMemberIdValue
      const isContactNo = data.Customer.ContactNo === sanitizedMemberIdValue
      const isEmail = data.Customer.Email === sanitizedMemberIdValue

      const isMatched =
        payload.MemberId === "MembershipNo"
          ? isMembership
          : payload.MemberId === "IdNo"
          ? isIdType
          : payload.MemberId === "ContactNo"
          ? isContactNo
          : payload.MemberId === "Email"
          ? isEmail
          : false

      if (!isMatched) {
        setError(
          "Get Collect Reward Failed",
          "Member Details did not match with rewards record"
        )
        return
      }

      data.Customer.Status !== "Active" &&
        setError("Customer is not Active", "")

      /** Get Reward Images */
      const { data: images } = yield call(CommonService.getMedias, {
        ObjectId: data.Reward?.RewardId,
        ObjectType: "RewardId",
      })

      if (images.rows?.length) yield put(setCollectedRewardImages(images.rows))
      /** End Get Reward Images */

      /** Set required data */
      if (!isCollected) {
        data.CollectedOutletCode = userInfo.building.code
      }
      /** End Set required data */

      yield put(setCollectedReward(data))
    } catch (err) {
      setError("Get Collect Reward Failed", err.response.data.message ?? "")
    }
  })
}

export function* onCollectRewardAPI() {
  yield takeEvery(ON_COLLECT_REWARD, function* ({ payload }) {
    yield put(setIsLoading(true))

    try {
      const { ReferenceCode, InvoiceNo, VoucherNo } = payload

      const userInfo = JSON.parse(localStorage.getItem("userinfo"))
      const localSystemTime = moment.utc().toISOString()
      let collectedDateTime

      try {
        const response = yield call(CommonService.getGlobalUTCTime)
        collectedDateTime =
          moment(response?.data?.dateTime).format("YYYY-MM-DD HH:mm:ss") ||
          localSystemTime
      } catch (error) {
        collectedDateTime = localSystemTime
      }

      if (userInfo) {
        const dataCollect = {
          CollectedById: userInfo.id + "",
          CollectedOutletCode: userInfo.building.code + "",
          CollectedDate: collectedDateTime,
          Status: "Collected",
          InvoiceNo,
          VoucherNo,
        }

        yield call(CustomerRewardService.updateCustomerReward, {
          ref_code: ReferenceCode,
          data: dataCollect,
        })

        yield put(
          createLoginActivity(
            `Collect Rewards. ReferenceCode - ${ReferenceCode}`
          )
        )

        setNotification({
          type: "success",
          message: "Collect Reward Success",
          description: "",
        })
      } else {
        setError("Collect Reward Failed")
      }
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Collect Reward Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Collect Reward Failed",
          description: error.message,
        })
      }
    } finally {
      yield put(setIsLoading(false))
    }
  })
}

export function* onUpdateCollectedRewardAPI() {
  yield takeEvery(ON_UPDATE_COLLECTED_REWARD, function* ({ payload }) {
    yield put(setIsLoading(true))

    try {
      const { ReferenceCode, InvoiceNo, VoucherNo } = payload

      const dataToUpdate = {
        InvoiceNo,
        VoucherNo,
      }

      yield call(CustomerRewardService.updateCustomerReward, {
        ref_code: ReferenceCode,
        data: dataToUpdate,
      })

      yield put(
        createLoginActivity(
          `Update Rewards in Collect Rewards. ReferenceCode - ${ReferenceCode}`
        )
      )

      setNotification({
        type: "success",
        message: "Update Collected Reward Success",
      })

      yield put(setIsLoading(false))
    } catch (error) {
      if (error?.response?.data) {
        const { message } = error.response.data
        setNotification({
          type: "error",
          message: "Update Collected Reward Failed",
          description: message,
        })
      } else {
        setNotification({
          type: "error",
          message: "Update Collected Reward Failed",
          description: error.message,
        })
      }
    } finally {
      yield put(setIsLoading(false))
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(getCollectedRewardAPI),
    fork(onCollectRewardAPI),
    fork(onUpdateCollectedRewardAPI),
  ])
}
