import setNotification from "components/shared-components/Notification"
import { isArray } from "lodash"
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { createLoginActivity } from "redux/actions/Auth"
import {
  setIsCancelled,
  setIsLoading,
  setReward,
  setRewardImages,
} from "redux/actions/CancelCollectReward"
import {
  GET_REWARD,
  ON_CANCEL_COLLECTED_REWARD,
  ON_UPDATE_COLLECTED_REWARD,
} from "redux/constants/CancelCollectReward"
import CommonService from "services/CommonService"
import CustomerRewardService from "services/CustomerRewardService"
import PointAdjustmentService from "services/PointAdjustmentService"

const setError = (message, description) =>
  setNotification({
    type: "error",
    message,
    description,
  })

export function* getRewardAPI() {
  yield takeEvery(GET_REWARD, function* ({ payload }) {
    try {
      const { data } = yield call(
        CustomerRewardService.getCustomerReward,
        payload.ReferenceCode
      )

      const sanitizeMemberIdValue = (id) => id.replace(/\s+/g, "").trim()

      const sanitizedMemberIdValue = sanitizeMemberIdValue(
        payload.MemberIdValue
      )

      data.MembershipNo =
        payload.MemberId === "MembershipNo"
          ? sanitizedMemberIdValue
          : data.Customer.CustomerMemberships[0].MembershipNo

      const isCollected = data.Status === "Collected"

      if (!isCollected) {
        setError(
          `The reward is ${data.Status}, cancellation is not allowed if the record Status is not Collected`
        )

        return
      }

      const isMembership = data.Customer.CustomerMemberships.some(
        ({ MembershipNo }) => MembershipNo === sanitizedMemberIdValue
      )
      const isIdType = data.Customer.IdNo === sanitizedMemberIdValue
      const isContactNo = data.Customer.ContactNo === sanitizedMemberIdValue
      const isEmail = data.Customer.Email === sanitizedMemberIdValue

      const isMatched =
        payload.MemberId === "MembershipNo"
          ? isMembership
          : payload.MemberId === "IdNo"
          ? isIdType
          : payload.MemberId === "ContactNo"
          ? isContactNo
          : payload.MemberId === "Email"
          ? isEmail
          : false

      if (!isMatched) {
        setError(
          "Get Reward Failed",
          "Member Details did not match with rewards record"
        )
        return
      }

      /** Get Reward Images */
      const { data: images } = yield call(CommonService.getMedias, {
        ObjectId: data.Reward?.RewardId,
        ObjectType: "RewardId",
      })

      if (images.rows?.length) yield put(setRewardImages(images.rows))
      /** End Get Reward Images */

      yield put(setReward(data))
    } catch (err) {
      setError("Get Reward Failed", err.response.data.message ?? "")
    }
  })
}

export function* onCancelCollectRewardAPI() {
  yield takeEvery(ON_CANCEL_COLLECTED_REWARD, function* ({ payload }) {
    yield put(setIsLoading(true))

    try {
      const { ReferenceCode, Remarks, MembershipNo } = payload

      const userInfo = JSON.parse(localStorage.getItem("userinfo"))

      if (userInfo) {
        const dataCancel = {
          VoucherNo: null,
          InvoiceNo: null,
          CollectedById: null,
          CollectedOutletCode: null,
          CollectedDate: null,
          Status: "Active",
          Remarks,
        }

        yield call(CustomerRewardService.updateCustomerReward, {
          ref_code: ReferenceCode,
          data: dataCancel,
        })

        const pointAdjustments = yield call(
          PointAdjustmentService.getPointAdjustments,
          {
            search: {
              MembershipNo,
              ObjectType: "ReferenceCode",
              ObjectValue: ReferenceCode,
              Status: "Active",
            },
          }
        )

        if (pointAdjustments.data.rows.length) {
          for (const pa of pointAdjustments.data.rows) {
            yield call(PointAdjustmentService.updatePointAdjustment, {
              HistoryId: pa.HistoryId,
              InvoiceNo: null,
              TransactionId: null,
            })
          }
        }

        yield put(
          createLoginActivity(
            `Cancel Collect Rewards. ReferenceCode - ${ReferenceCode}`
          )
        )

        setNotification({
          type: "success",
          message: "Cancel Reward Success",
          description: "",
        })
        yield put(setIsCancelled(true))
      } else {
        setError("Cancel Reward Failed")
      }
    } catch (err) {
      setError(
        "Cancel Reward Failed",
        isArray(err.response.data?.message)
          ? err.response.data?.message[0]
          : err.response.data.message ?? ""
      )
    }

    yield put(setIsLoading(false))
  })
}

export function* onUpdateCollectRewardAPI() {
  yield takeEvery(ON_UPDATE_COLLECTED_REWARD, function* ({ payload }) {
    yield put(setIsLoading(true))

    try {
      const { ReferenceCode, Remarks, InvoiceNo, VoucherNo, MembershipNo } =
        payload

      const userInfo = JSON.parse(localStorage.getItem("userinfo"))

      if (userInfo) {
        const dataUpdate = {
          InvoiceNo,
          VoucherNo,
          Remarks,
        }

        yield call(CustomerRewardService.updateCustomerReward, {
          ref_code: ReferenceCode,
          data: dataUpdate,
        })

        const pointAdjustments = yield call(
          PointAdjustmentService.getPointAdjustments,
          {
            search: {
              MembershipNo,
              ObjectType: "ReferenceCode",
              ObjectValue: ReferenceCode,
              Status: "Active",
            },
          }
        )

        if (pointAdjustments.data.rows.length) {
          for (const pa of pointAdjustments.data.rows) {
            yield call(PointAdjustmentService.updatePointAdjustment, {
              HistoryId: pa.HistoryId,
              InvoiceNo: InvoiceNo,
              TransactionId: VoucherNo,
            })
          }
        }

        yield put(
          createLoginActivity(
            `Update Rewards in Cancel Collect Rewards. ReferenceCode - ${ReferenceCode}`
          )
        )

        setNotification({
          type: "success",
          message: "Update Reward Success",
          description: "",
        })
      } else {
        setError("Update Reward Failed")
      }
    } catch (err) {
      setError(
        "Update Reward Failed",
        isArray(err.response.data?.message)
          ? err.response.data?.message[0]
          : err.response.data.message ?? ""
      )
    } finally {
      yield put(setIsLoading(false))
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(getRewardAPI),
    fork(onCancelCollectRewardAPI),
    fork(onUpdateCollectRewardAPI),
  ])
}
