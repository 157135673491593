import setNotification from "components/shared-components/Notification"
import { all, call, fork, put, takeEvery } from "redux-saga/effects"
import { createLoginActivity } from "redux/actions/Auth"
import {
  setCustomerMembership,
  setResultLoadImageRewards,
  setResultLoadRewards,
  setResultOnRedeemReward,
} from "redux/actions/RedeemReward"
import {
  LOAD_IMAGE_REWARDS,
  LOAD_REWARDS,
  ON_REDEEM_REWARD,
  SEARCH_CUSTOMER_MEMBERSHIP,
} from "redux/constants/RedeemReward"
import CommonService from "services/CommonService"
import CustomerRewardService from "services/CustomerRewardService"
import RedeemRewardService from "services/RedeemRewardService"
import RewardService from "services/RewardService"

const setError = (message, description) =>
  setNotification({
    type: "error",
    message,
    description,
  })

export function* searchCustomerMembershipAPI() {
  yield takeEvery(SEARCH_CUSTOMER_MEMBERSHIP, function* ({ payload }) {
    try {
      const searchQuery = { [payload.MemberId]: payload.MemberIdValue }

      const { data } = yield call(
        RedeemRewardService.searchCustomerMembership,
        searchQuery
      )

      if (!data.count) {
        yield put(setCustomerMembership(null))

        setError("Get Customer Failed", "Customer Membership Not Found.")
        return
      }

      yield put(setCustomerMembership(data.rows[0]))

      data.rows[0].Status !== "Active" && setError("Customer is not Active", "")
    } catch (err) {
      yield put(setCustomerMembership(null))
      setError("Get Customer Failed", err.response.data.message ?? "")
    }
  })
}

export function* loadRewardsAPI() {
  yield takeEvery(LOAD_REWARDS, function* ({ payload }) {
    try {
      const searchQuery = {
        rows: payload?.rows ?? 20,
        page: 1,
        search: payload?.search,
      }

      const { data } = yield call(RewardService.getListReward, searchQuery)

      if (!data.count) {
        setError("Get Rewards Failed", "Rewards are empty.")
        return
      }

      let rewards = data.rows
      if (!payload.fetchAllReward) {
        rewards = rewards.filter(({ PointDeduct }) => PointDeduct)
      }

      yield put(setResultLoadRewards(rewards))
    } catch (err) {
      setError("Load Reward Failed", err.response?.data?.message ?? "")
    }
  })
}

export function* loadImageRewardsAPI() {
  yield takeEvery(LOAD_IMAGE_REWARDS, function* ({ payload }) {
    try {
      const { data } = yield call(CommonService.getMedias, payload)

      yield put(setResultLoadImageRewards(data?.rows))
    } catch (err) {
      setError("Listing media failed.", err.response.data.message ?? "")
    }
  })
}

export function* onRedeemRewardAPI() {
  yield takeEvery(ON_REDEEM_REWARD, function* ({ payload }) {
    try {
      const { CustomerId, RewardId, IsBirthdayPointDeduct, Type } = payload

      const isPremiumGift = Type === "Premium Gift"

      const CollectedOutletCode = JSON.parse(localStorage.getItem("userinfo"))
        ?.building?.code

      const customerRewardPayload = {
        CustomerId,
        RewardId,
        IsBirthdayPointDeduct,
      }

      if (CollectedOutletCode && isPremiumGift)
        customerRewardPayload.CollectedOutletCode = CollectedOutletCode

      const { data } = yield call(
        CustomerRewardService.createCustomerReward,
        customerRewardPayload
      )

      yield put(
        createLoginActivity(
          `Redeem Rewards. ReferenceCode - ${data.ReferenceCode}`
        )
      )

      yield put(setResultOnRedeemReward(data))

      if (payload.callback) {
        payload.callback(data)
      }
    } catch (err) {
      setError("Redeem Reward Failed.", err.response.data?.message ?? "")
    }
  })
}

export default function* rootSaga() {
  yield all([
    fork(searchCustomerMembershipAPI),
    fork(loadRewardsAPI),
    fork(loadImageRewardsAPI),
    fork(onRedeemRewardAPI),
  ])
}
