export const GET_REWARD = "GET_REWARD_CANCEL_CLAIM_REWARD"
export const SET_REWARD = "SET_REWARD_CANCEL_CLAIM_REWARD"
export const SET_REWARD_IMAGES = "SET_REWARD_IMAGES_CANCEL_CLAIM_REWARD"
export const SET_IS_CANCELLED = "SET_IS_CANCELLED_CANCEL_CLAIM_REWARD"

export const SET_IS_LOADING = "SET_IS_LOADING_CANCEL_CLAIM_REWARD"

export const ON_CANCEL_CLAIMED_REWARD = "ON_CANCEL_CLAIMED_REWARD"

export const ON_UPDATE_CLAIMED_REWARD = "ON_UPDATE_CLAIMED_REWARD"

export const RESET_CANCEL_CLAIM_REWARD = "RESET_CANCEL_CLAIM_REWARD"
