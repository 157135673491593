import {
  GET_REWARD,
  SET_IS_CANCELLED,
  SET_IS_LOADING,
  ON_CANCEL_CLAIMED_REWARD,
  SET_REWARD,
  SET_REWARD_IMAGES,
  ON_UPDATE_CLAIMED_REWARD,
  RESET_CANCEL_CLAIM_REWARD,
} from "redux/constants/CancelClaimReward"

export const getReward = (data) => {
  return {
    type: GET_REWARD,
    payload: data,
  }
}

export const setReward = (data) => {
  return {
    type: SET_REWARD,
    payload: data,
  }
}

export const setIsLoading = (isLoading) => {
  return {
    type: SET_IS_LOADING,
    payload: isLoading,
  }
}

export const setIsCancelled = (isCancelled) => {
  return {
    type: SET_IS_CANCELLED,
    payload: isCancelled,
  }
}

export const setRewardImages = (data) => {
  return {
    type: SET_REWARD_IMAGES,
    payload: data,
  }
}

export const onCancelReward = (data) => {
  return {
    type: ON_CANCEL_CLAIMED_REWARD,
    payload: data,
  }
}

export const onUpdateReward = (data) => {
  return {
    type: ON_UPDATE_CLAIMED_REWARD,
    payload: data,
  }
}

export const reset = () => {
  return {
    type: RESET_CANCEL_CLAIM_REWARD,
    payload: null,
  }
}
